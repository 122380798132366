import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ZipContent from '@/components/ZipContent'
import ReviewResults from '@/components/ReviewResults'

import WordElement from '@/word/word-element.vue'
import WordT from '@/word/word-t.vue'
import WordBr from '@/word/word-br.vue'
import WordTbl from '@/word/word-tbl.vue'
import WordP from '@/word/word-p.vue'
import WordR from '@/word/word-r.vue'
import WordNum from '@/word/word-num.vue'
import WordDrawing from '@/word/word-drawing.vue'
import WordObject from '@/word/word-object.vue'
import WordPage from '@/word/word-page.vue'
import WordDelText from '@/word/word-del-text.vue'
import WordDel from '@/word/word-del.vue'
import WordIns from '@/word/word-ins.vue'
import WordCommentRangeStart from '@/word/word-comment-range-start.vue'
import WordCommentRangeEnd from '@/word/word-comment-range-end.vue'
import WordBookmarkStart from '@/word/word-bookmark-start.vue'
import WordBookmarkEnd from '@/word/word-bookmark-end.vue'
import WordTab from '@/word/word-tab.vue'
import WordStyle from '@/word/word-style.vue'
import WordHyperlink from '@/word/word-hyperlink.vue'
import WordCommentReference from '@/word/word-comment-reference.vue'
import WordAlternateContent from '@/word/word-alternate-content.vue'

Vue.config.productionTip = false

Vue.component('ZipContent', ZipContent)
Vue.component('ReviewResults', ReviewResults)
Vue.component('WordElement', WordElement)
Vue.component('WordT', WordT)
Vue.component('WordBr', WordBr)
Vue.component('WordTbl', WordTbl)
Vue.component('WordP', WordP)
Vue.component('WordR', WordR)
Vue.component('WordNum', WordNum)
Vue.component('WordDrawing', WordDrawing)
Vue.component('WordObject', WordObject)
Vue.component('WordPage', WordPage)
Vue.component('WordDelText', WordDelText)
Vue.component('WordDel', WordDel)
Vue.component('WordIns', WordIns)
Vue.component('WordCommentRangeStart', WordCommentRangeStart)
Vue.component('WordCommentRangeEnd', WordCommentRangeEnd)
Vue.component('WordBookmarkStart', WordBookmarkStart)
Vue.component('WordBookmarkEnd', WordBookmarkEnd)
Vue.component('WordTab', WordTab)
Vue.component('WordStyle', WordStyle)
Vue.component('WordHyperlink', WordHyperlink)
Vue.component('WordCommentReference', WordCommentReference)
Vue.component('WordAlternateContent', WordAlternateContent)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
