<template>
  <!-- <div class="editor" @click="$refs.hiddenInput.focus()"> -->
  <div class="editor">
    <word-style v-if="doc" :styles="doc.styles"></word-style>
    <div class="tool">
      <div>
        <input type="file" @change="handleFile" accept=".docx">
      </div>
      <div style="padding-top: 20px">
        <button @click="exportDocx">导出</button>
      </div>
    </div>

    <div ref="word">
      <word-page
        v-if="doc && doc.document"
        :element="doc.document.body"
        contenteditable
        @compositionstart.native="onCompositionStart"
        @compositionend.native="onCompositionEnd"
        @keydown.native="onKeyDown">
        <div class="right-sidebar" contenteditable="false">
          <select v-model="showType">
            <option value="review">审查结果</option>
            <option value="zip">zip</option>
          </select>
          <ReviewResults v-if="showType === 'review'" :results="reviewResults"></ReviewResults>
          <ZipContent v-else :zip="zip"></ZipContent>
        </div>
      </word-page>
    </div>
    <!-- <Cursor/> -->
    <!-- <input
      class="hidden-input"
      v-model="inputValue"
      ref="hiddenInput"
      @keydown="handleKeyDown"
    > -->
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import docStore from '@/components/docstore'
const Parser = require('@/parser')
const Serializer = require('@/serializer')
import * as utils from '@/word/utils'
import Editor from '@/editor'

export default {
  data() {
    return {
      cursor: store.state.cursor,
      inputValue: '',
      doc: docStore.state,
      zip: null,
      showType: 'zip',
      reviewResults: []
    }
  },
  async created() {
    var fileUrl = '/商铺租赁合同01-承租方.docx'
    // fileUrl = '/comment.docx'
    fileUrl = '/test/居理新房服务器采购合同-20181210-原始-mecheck.docx'
    const res = await axios.get(fileUrl, {
      responseType: 'blob'
    })
    this.handleDocxBlob(res.data)
    window.utils = utils
    window.docStore = docStore
  },
  mounted() {
    this.editor = window.editor = new Editor({
      root: this.$refs.word
    })
  },
  // watch: {
  //   inputValue(value) {
  //     if (value) {
  //       this.inputValue = ''
  //       store.commit('doc/update', {
  //         op: 'addText',
  //         value,
  //         range: this.getSelection()
  //       })
  //       store.commit('cursor/move', value.length)
  //     }
  //   }
  // },
  methods: {
    getSelection() {
      return [store.state.cursor, store.state.cursor]
    },
    onCompositionStart(ev) {
      ev.preventDefault()
      this.editor.cacheSelection()
    },
    onCompositionEnd(ev) {
      this.editor.insertText(ev.data)
      this.editor.clearSelectionCache()
    },
    async onKeyDown(ev) {
      if (ev.altKey || ev.ctrlKey) {
        return
      }
      if (ev.key === 'Process') {
        // 中文输入法
        return
      }
      if (ev.key.length !== 1 && ['Backspace', 'Delete', 'Enter'].indexOf(ev.key) === -1) {
        console.log('unknown key', ev)
        return
      }

      ev.preventDefault()

      if (ev.key === 'Backspace') {
        this.editor.deleteForward()
      } else if (ev.key === 'Delete') {
        this.editor.deleteBackword()
      } else if (ev.key === 'Enter') {
        this.editor.insertBreak()
      } else {
        this.editor.insertText(ev.key)
      }
    },
    testBlob() {
      var file = document.querySelector('input').files[0]
      var url = URL.createObjectURL(file)
      window.open(url, '_blank')
    },
    handleKeyDown(ev) {
      if (ev.code === 'Backspace') {
        store.commit('doc/update', {
          op: 'deleteText',
          range: this.getSelection()
        })
        store.commit('cursor/move', -1)
      }
    },
    clear() {
      utils.sharedData.numState = {}
      this.zip = null
      this.reviewResults = []
      docStore.reset()
    },
    async handleDocxBlob(blob) {
      this.reviewContract(blob)
      var parser = new Parser()
      var doc = await parser.parseDocx(blob)
      this.zip = doc.zip
      delete doc.zip
      docStore.update(Object.freeze(_.cloneDeep(doc)))
      console.log(doc)
    },
    async reviewContract(blob) {
      if (!(blob instanceof File)) {
        blob = new File([blob], 'default.docx', {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
      }
      var formData = new FormData()
      formData.append('type', 'house_lease')
      formData.append('file', blob)
      var { data } = await axios.post('/api/review', formData)
      var labels = data.results[0]?.res?.labels
      var results = []
      for (var key in labels) {
        labels[key].labels.forEach(item => {
          if (item.pos) {
            item.pos.forEach(pos => {
              if (item.value !== '通过') {
                results.push({
                  commentId: null,
                  pos,
                  suggestion: item.suggestion,
                  type: item.type,
                  value: item.value
                })
              }
            })
          }
        })
      }
      // 排序
      results.sort((a, b) => {
        var paraA = Number(a.pos.coordinates)
        var paraB = Number(b.pos.coordinates)
        if (paraA > paraB) {
          return 1
        } else if (paraA < paraB) {
          return -1
        } else {
          var aLeft = a.pos.left || 0
          var bLeft = b.pos.left || 0
          if (aLeft > bLeft) {
            return 1
          } else if (bLeft > aLeft) {
            return -1
          }
        }
      })
      console.log('review results', results)
      this.reviewResults = results
      for (let result of results) {
        var { pos } = result
        var paraLength = utils.getText(this.editor.getElementByPath([pos.coordinates])).length
        var range = this.editor.getRange(pos.coordinates, pos.left || 0, pos.coordinates, pos.right || paraLength)
        var commentId = this.editor.insertComment(range, {
          text: result.suggestion
        })
        result.commentId = commentId
        await this.sleep(0) // 懒加载
      }

    },
    sleep(time) {
      // await this.$nextTick()
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    },
    exportDocx() {
      var serializer = new Serializer()
      serializer.format({
        zip: this.zip,
        ...docStore.state
      })
    },
    async handleFile(ev) {
      console.log(ev)
      var file = ev.target.files[0]
      if (file) {
        this.clear()
        await this.$nextTick()
        this.handleDocxBlob(file)
      }
    }
  }
}
</script>

<style lang="scss">
.hidden-input {
  position: absolute;
  opacity: 0;
}

.right-sidebar {
  position: absolute;
  top: 0;
  right: -300px;
  width: 280px;
  select {
    margin-bottom: 20px;
  }
}

.word-page {
  // width: 794px;
  position: relative;
  margin: 0 auto;
  outline: none;
  background: #fff;
  box-shadow: rgb(158 161 165 / 40%) 0px 2px 12px 0px;
  // padding: 30px;
  color: #000;
  font-family: '宋体';
  margin-top: 100px;
  margin-bottom: 100px;
  line-height: 1.297;
  tab-size: 2em; /* 经验所得 */
  // * {
  //   box-sizing: border-box;
  // }
  .word-p {
    white-space: pre-wrap;
    word-break: break-all;
  }
  .word-p-content {
    display: inline-block;
  }
  .word-del-text {
    text-decoration: line-through;
  }
  .word-del {
    color: red;
  }
  .word-ins {
    color: red;
  }
  .word-suff {
    display: inline-block;
  }
  .word-page-break {
    line-height: 300px;
    font-size: 12px;
    text-align: center;
    color: #ddd;
    page-break-before: always;
  }
  .word-table {
    border-collapse: collapse;
    td {
      // border: 1px solid #000;
      height: 20px;
      vertical-align: baseline;
    }
  }
  .word-page-sep {
    position: absolute;
    // border-top: 1px solid #ccc;
    width: 100%;
    left: 0;
    color: #ccc;
    &:hover {
      cursor: pointer;
      border-top: 1px solid #ccc;
      color: #333;
    }
    span {
      position: absolute;
      top: 0;
      font-size: 13px;
      left: -10px;
      transform: translate(-100%, -50%);
    }
  }

  // [data-tag=body] {
  //   border: 1px solid gray;
  // }
}
</style>
