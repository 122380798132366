// 保存批注，高亮，书签等信息
import Vue from 'vue'
import _ from 'lodash'
const rangeStore = {
  state: Vue.observable({
    ranges: [] // { start, end }
  }),
  addStart(name, startPoint) {
    var range = _.find(this.state.ranges, range => range.name === name)
    if (range) {
      range.start = startPoint
    } else {
      this.state.ranges.push({
        name,
        start: startPoint,
        end: null
      })
    }
  },
  addEnd(name, endPoint) {
    var range = _.find(this.state.ranges, range => range.name === name)
    if (range) {
      range.end = endPoint
    } else {
      this.state.ranges.push({
        name,
        start: null,
        end: endPoint
      })
    }
  },
  addRange(name, startPoint, endPoint) {
    this.state.ranges.push({
      name,
      start: startPoint,
      end: endPoint
    })
  }
}

window.rangeStore = rangeStore

export default rangeStore
