<template>
  <div class="word-p" :style="style" :class="pClass" :data-para-id="element.paraId">
    <word-num v-if="numPr" :numPr="numPr"></word-num>
    <slot></slot>
    <div v-if="hasPageBreak" class="word-page-break">分页符</div>
  </div>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import docStore from '@/components/docstore.js'
import _ from 'lodash'
import * as utils from '@/word/utils.js'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    styleId() {
      return this.element?.pPr?.pStyle?.val
    },
    numPr() {
      return this.element.pPr?.numPr || this.relatedStyle?.pPr?.numPr
    },
    hasPageBreak() {
      return _.some(this.element.content, item => {
        return _.some(item.content, item => {
          return item.name === 'br' && item.type === 'page'
        })
      })
    },
    pClass() {
      var styleId = this.styleId
      if (styleId != null) {
        return `word-style-${styleId}`
      } else {
        var matched = utils.getDefaultStyle()
        if (matched) {
          return `word-style-${matched.styleId}`
        }
      }
    },
    style() {
      var props = this.element.pPr
      if (!props) return
      var style = {}
      if (props.numPr) {
        var numInfo = utils.getNumInfo(props.numPr)
        if (numInfo) {
          Object.assign(style, utils.props2style(numInfo.pPr))
        }
      }
      Object.assign(style, utils.props2style(props)) // 可覆盖 num 中的样式
      Object.assign(style, this.getLineHeightStyle())
      return style
    },
    relatedStyle() {
      var styleList = docStore.state.styles?.styles?.content
      var matched = _.find(styleList, item => {
        if (this.styleId) {
          return item.styleId == this.styleId
        }
        return item.name.val === 'Normal'
      })
      return matched
    }
  },
  methods: {
    getLineHeightStyle() {
      var props = this.element.pPr

      // lineHeight 依赖文字大小，需要自己 content 和 style，只能自己算
      var maxFontSize = _.max(_.map(this.element.content, item => {
        return Number(item.rPr?.sz?.val) || Number(item.rPr?.szCs?.val) || 0
      }))

      var relatedStyle = this.relatedStyle || utils.getDefaultStyle()
      if (relatedStyle) {
        var styleFontSize = Number(relatedStyle.rPr?.sz?.val) || Number(relatedStyle.rPr?.szCs?.val)
        maxFontSize = Math.max(maxFontSize || 0, styleFontSize || 0)
      }

      var spacing = props.spacing
      if (!spacing) {
        // 必须有 spacing，固定值可以不要 font size
        return null
      }

      var lineHeightStyle = utils.getLineHeight(maxFontSize / 2, spacing)
      return lineHeightStyle
    }
  }
}
</script>
