import docStore from '@/components/docstore'
import Vue from 'vue'
import { nanoid } from 'nanoid'
// Editor 仅在前端运行, 未来可以把数据运算放入另一个文件
class Editor {
  // 方法可参考: https://rain120.github.io/athena/zh/slate/api/nodes.html#editor
  constructor(opt) {
    opt = opt || {}
    this.root = opt.root
    this.selection = null
  }

  async insertText(text) {
    var { path, offset } = this.getCurrentPoint()
    console.log('insert', path, offset, text)
    var element = this.getElementByPath(path)
    element.text = element.text.slice(0, offset) + text + element.text.slice(offset)
    offset += text.length
    await this.updateCursor(path, offset)
  }

  async insertBreak() {
    // 回车换段
    var { path, offset } = this.getCurrentPoint()
    console.log('insert break', path, offset)
    var paraIndex = path[0]
    var body = docStore.state.document.body
    var para = {
      content: [{
        tagName: 'r',
        content: [{
          tagName: 't',
          text: '新的一行'
        }]
      }],
      paraId: nanoid(10),
      tagName: 'p'
    }
    body.content.splice(paraIndex + 1, 0, para)
  }

  nodes(opt) {
    // slate 的 nodes 是一个生成器, 参考 https://github.com/ianstormtaylor/slate/blob/main/packages/slate/src/interfaces/node.ts#L479
    const {
      from,
      reverse = false,
      pass
    } = opt
    var path = from
    var visited = new Set()
    var node
    while (true) {
      if (path.length === 0) {
        break
      }

      if (node && pass && pass(path, node)) {
        return path
      }

      node = node || this.getElementByPath(path)

      // 先找儿子
      if (node.content && node.content.length && !visited.has(path.join('-'))) {
        var childIndex = reverse ? node.content.length - 1 : 0
        path = path.concat(childIndex) // 必然存在 node
      } else {
        // 找不到儿子找兄弟
        var siblingPath = path.slice(0, -1).concat(path.slice(-1)[0] + (reverse ? -1 : 1))
        var siblingElement = this.getElementByPath(siblingPath)
        if (siblingElement) {
          path = siblingPath
        } else {
          // 找不到兄弟就找爸爸
          path = path.slice(0, -1)
          visited.add(path.join('-'))
        }
      }
      node = this.getElementByPath(path)
    }
  }

  async deleteForward() {
    // Backspace
    this.deleteForwardByPoint(this.getCurrentPoint())
  }

  async deleteForwardByPoint(point) {
    // TODO 删到回车需要单独删一次回车并合并段落
    var { path, offset } = point
    if (offset === 0) {
      var prevPoint = this.getPrevPoint(point)
      if (!prevPoint) {
        return
      }
      return this.deleteForwardByPoint(prevPoint)
    }
    console.log('delete forward', path, offset)
    var element = this.getElementByPath(path)
    if (offset >= 1) {
      element.text = element.text.slice(0, offset - 1) + element.text.slice(offset)
      offset -= 1
      await this.updateCursor(path, offset)
    }
  }

  getRange(startPara, startChar, endPara, endChar) {
    var start = this.paraChar2Point([startPara, startChar])
    var end = this.paraChar2Point([endPara, endChar])
    if (!end.path) {
      debugger
    }
    return {
      start,
      end
    }
  }

  paraChar2Point([paraIndex, charIndex]) {
    var path = this.nodes({
      from: [paraIndex, 0],
      reverse: false,
      pass(_, node) {
        if (node.text) {
          if (charIndex <= node.text.length) {
            return true
          }
          charIndex -= node.text.length
        }
      }
    })
    return {
      path,
      offset: charIndex
    }
  }

  getPrevPoint(point) {
    // 此处仅适用 offset = 0
    var { path } = point
    var prevPath = this.nodes({
      from: path,
      reverse: true,
      pass(_, node) {
        if (node.text) {
          return true
        }
      }
    })
    if (!prevPath) {
      return null
    }
    var prevElement = this.getElementByPath(prevPath)
    return {
      path: prevPath,
      offset: prevElement.text.length
    }
  }

  async deleteBackword() {
    // Delete
    var { path, offset } = this.getCurrentPoint()
    console.log('delete backword', path, offset)
    var element = this.getElementByPath(path)
    element.text = element.text.slice(0, offset) + element.text.slice(offset + 1)
    await this.updateCursor(path, offset)
  }

  async updateCursor(path, offset) {
    await this.nextTick()
    var textElement = this.getDomByPath(path)
    var newTextNode = textElement.firstChild
    if (newTextNode) {
      offset = Math.max(0, offset)
      offset = Math.min(offset, newTextNode.length)
      window.getSelection().setBaseAndExtent(newTextNode, offset, newTextNode, offset)
    }
  }

  nextTick() {
    return Vue.nextTick()
  }

  delete() {

  }

  getElementByPath(path) {
    if (!path) debugger
    var element = docStore.state.document.body
    for (let i = 0; i < path.length; i++) {
      if (path[i] === Infinity) {
        element = element.content.slice(-1)
      } else {
        if (!element.content) {
          debugger
        }
        element = element.content[path[i]]
      }
    }
    return element
  }

  getDomByPath(path) {
    // 不知道有没有性能问题?
    return this.root.querySelector(`[data-path="${path.join('-')}"]`)
  }

  getCurrentPoint() {
    var selection = this.selection || window.getSelection()
    var textNode = selection.anchorNode
    var textElement = textNode.parentNode
    var path = textElement.dataset.path.split('-').map(num => parseInt(num))
    return {
      path,
      // textElement, // 如果没有这个值, 需要做 path: element 的缓存, 成本太大
      offset: selection.anchorOffset
    }
  }

  getSelectRange() {
    var selection = this.selection || window.getSelection()
    return {
      start: {
        path: selection.anchorNode.parentNode.dataset.path.split('-').map(num => parseInt(num)),
        offset: selection.anchorOffset
      },
      end: {
        path: selection.focusNode.parentNode.dataset.path.split('-').map(num => parseInt(num)),
        offset: selection.focusOffset
      }
    }
  }

  initCommentXML() {
    var list = docStore.state.relationships2.Relationships
    // debugger
    var maxId = Math.max(...list.map(item => parseInt(/\d+/.exec(item.Id)[0])))
    list.push({
      Id: `rId${maxId + 1}`,
      Target: 'comments.xml',
      Type: "http://schemas.microsoft.com/office/2011/relationships/comments",
      tagName: "Relationship"
    })
  }

  insertComment(range, comment) {
    comment = comment || {}
    var { start, end } = range
    var idList = _.map(docStore.state.comments, comment => Number(comment.id))
    var id = Math.max(Math.max(...idList), 0) + 1
    this.insertElement(end, {
      tagName: 'commentRangeEnd',
      id
    })
    this.insertElement(end, {
      tagName: 'r',
      content: [{
        tagName: 'commentReference',
        id
      }]
    })
    this.insertElement(start, {
      tagName: 'commentRangeStart',
      id
    })
    if (!docStore.state.comments) {
      docStore.state.comments = []
      this.initCommentXML()
    }
    docStore.state.comments = docStore.state.comments || []
    docStore.state.comments.push({
      author: comment.author || 'docxjs',
      date: comment.date || new Date().toISOString(),
      id,
      initials: '',
      tagName: 'comment',
      content: [{
        "tagName": "p",
        "pPr": {
            "tagName": "pPr",
            "pStyle": {
                "tagName": "pStyle",
                "val": "2"
            },
            "rPr": {
                "tagName": "rPr",
                "rFonts": {
                    "tagName": "rFonts",
                    "hint": "default",
                    "eastAsiaTheme": "minorEastAsia"
                },
                "lang": {
                    "tagName": "lang",
                    "val": "en-US",
                    "eastAsia": "zh-CN"
                }
            }
        },
        "content": [
            {
                "tagName": "r",
                "rPr": {
                    "tagName": "rPr",
                    "rFonts": {
                        "tagName": "rFonts",
                        "hint": "eastAsia"
                    },
                    "lang": {
                        "tagName": "lang",
                        "val": "en-US",
                        "eastAsia": "zh-CN"
                    }
                },
                "content": [
                    {
                        "tagName": "t",
                        "text": comment.text
                    }
                ]
            }
        ],
        "paraId": "24C719FC"
    }]
    })
    return id
  }

  insertElement(point, obj) {
    var element = this.getElementByPath(point.path)
    var parentElement = this.getElementByPath(point.path.slice(0, -2))
    var elementIndex = point.path.slice(-2)[0]
    if (point.offset <= 0) {
      parentElement.content.splice(elementIndex, 0, obj)
    } else if (point.offset >= element.text.length) {
      parentElement.content.splice(elementIndex + 1, 0, obj)
    } else {
      var run = this.getRunElementByPath(point.path)
      parentElement.content.splice(elementIndex, 1, ...this.splitRun(run, point.offset))
      parentElement.content.splice(elementIndex + 1, 0, obj)
    }
  }

  getRunElementByPath(path) {
    while (path && path.length) {
      var element = this.getElementByPath(path)
      if (element.tagName === 'r') {
        return element
      }
      path = path.slice(0, -1)
    }
  }

  splitRun(run, offset) {
    // 默认 run 下面只有一个 t
    if (run.content[0]) {
      var newRun = _.cloneDeep(run)
      var text = run.content[0].text || ''
      run.content[0].text = text.slice(0, offset)
      newRun.content[0].text = text.slice(offset)
      return [run, newRun]
    }
  }

  cacheSelection() {
    var selection = window.getSelection()
    this.selection = {
      anchorNode: selection.anchorNode,
      focusNode: selection.focusNode,
      anchorOffset: selection.anchorOffset,
      focusOffset: selection.focusOffset
    }
  }

  clearSelectionCache() {
    this.selection = null
  }
}

export default Editor
