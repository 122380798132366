
<template>
  <span class="word-tab" v-html="'\t'"></span>
</template>
<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin]
}
</script>
