// 指定属性名是属于数组还是对象
// 如果一个属性下面同时有对象+数组，那应该把数组统一放到 content 下面
// 例如 obj.pPr, obj.content[p, tbl]
// 无关业务的 xml 解析
const cheerio = require('cheerio')

class Parser {
  parse(xmlString) {
    var $ = cheerio.load(xmlString, {
      xml: {
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    this.$ = $

    // 孩子标签属于 content, 也就是说这个标签是内容而不是属性
    this.nameInContent = ['p', 'r', 't', 'hyperlink', 'br', 'tab', 'delText', 'tbl', 'tr', 'tc', 'del', 'ins', 'lsdException', 'compatSetting', 'rsid', 'font', 'drawing', 'commentRangeEnd', 'commentRangeStart', 'commentReference', 'bookmarkStart', 'bookmarkEnd', 'style', 'lvl', 'object', 'AlternateContent']

    // 确定标签本身就是列表，不含其他属性
    this.nameIsList = ['tblGrid', 'Relationships', 'comments', 'numbering']
    return this.parseElement($.root()[0])
  }

  getPureName(name) {
    name = name || ''
    var nameArr = name.split(':')
    name = nameArr[nameArr.length - 1]
    return name
  }

  parseList(children) {
    children = children || []
    return children.map(child => {
      return this.parseElement(child)
    })
  }

  parseElement(element) {
    var tagName = this.getPureName(element.name)
    var ret = {
      tagName // 和 html 一样使用 tagName, 防止和 props name 冲突
    }
    if (element.children) {
      // 标签孩子
      element.children.forEach(child => {
        if (child.type === 'tag') {
          var name = this.getPureName(child.name)
          if (this.nameInContent.indexOf(name) !== -1) {
            ret.content = ret.content || []
            ret.content.push(this.parseElement(child))
          } else if (this.nameIsList.indexOf(name) !== -1) {
            ret[name] = this.parseList(child.children)
          } else {
            ret[name] = this.parseElement(child)
          }
        } else if (child.type === 'text') {
          ret.text = child.data
        }
      })
    }

    if (element.attribs) {
      // 标签属性
      for (var key in element.attribs) {
        var newKey = tagName === 'document' ? key : this.getPureName(key)
        ret[newKey] = element.attribs[key]
      }
    }
    return ret
  }
}



module.exports = Parser
