<template>
  <span class="word-r" @click="handleClick" :style="style" :data-path="this.getPath().join('-')" :class="rClass">
    <word-num v-if="element.rPr && element.rPr.numPr" :numPr="element.rPr.numPr"></word-num>
    <slot></slot>
  </span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import docStore from '@/components/docstore.js'
import * as utils from '@/word/utils.js'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    rClass() {
      var styleId = this.element?.rPr?.rStyle?.val
      if (styleId != null) {
        return `word-style-${styleId}`
      }
    },
    style() {
      var props = this.element.rPr
      var style = utils.props2style(props) || {}

      // run 上面要显示批注高亮等
      var comments = this.getComments()
      // var belongRanges = this.getBelongRanges() || []
      // var commentBelongRanges = belongRanges.filter(item => {
      //   return item.name.indexOf('comment') === 0
      // })
      if (comments.length) {
        var isSelectedComment = comments.some(item => item.id == docStore.state.selectedCommentId)
        if (isSelectedComment) {
          style['background-color'] = 'orange'
        } else {
          style['text-decoration'] = 'underline'
          style['text-decoration-thickness'] = '2px'
          style['text-decoration-color'] = 'orange'
        }
      }
      return style
    }
  },
  methods: {
    handleClick() {
      var comments = this.getComments()
      if (comments.length) {
        console.log('selected comments', comments)
        docStore.state.selectedCommentId = comments[0].id
      }
    },
    getComments() {
      var comments = []
      this.getBelongRanges().forEach(item => {
        var [type, id] = item.name.split('-')
        if (type === 'comment') {
          var comment = _.find(docStore.state.comments, item => item.id == id)
          if (!comment) {
            return
          }
          var text = comment.content[0].content[0].content[0].text
          comments.push({
            id: comment.id,
            text,
            author: comment.author,
            date: comment.date
          })
        }
      })
      return comments
    }
  }
}
</script>
