
<template>
  <img
    :src="imageInfo.url"
    :title="imageInfo.name"
    :style="element.shape.style">
</template>
<script>
import docStore from '@/components/docstore'
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    imageInfo() {
      var rId = this.element.shape.imagedata.id
      var file = docStore.state.relationships[rId]
      return {
        url: URL.createObjectURL(file.content),
        name: file.target
      }
    }
  }
}
</script>
