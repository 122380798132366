import _ from 'lodash'
import Vue from 'vue'

export default {
  state: Vue.observable({
    document: null,
    numbering: null,
    comments: null,
    styles: null,
    selectedCommentId: null,
    docHeight: 0 // 文档高度
  }),
  update(newState) {
    Object.assign(this.state, newState)
  },
  reset() {
    Object.assign(this.state, {
      document: null,
      numbering: null,
      comments: null,
      styles: null,
      selectedCommentId: null,
      docHeight: 0
    })
  },
  updateDocHeight: _.debounce(function() {
    var el = document.querySelector('.word-page')
    console.log('update doc height', el.offsetHeight)
    this.state.docHeight = el.offsetHeight
  }, 300, {
    trailing: true
  })
}
