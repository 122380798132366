const cheerio = require('cheerio')
const JSZIP = require('jszip')

class Serializer {
  async format(doc) {
    var { zip, document } = doc
    var newZip = new JSZIP()
    for (let key in zip.files) {
      if (zip.files[key].dir) {
        newZip.folder(key)
      } else if (key === 'word/document.xml') {
        var documentXML = this.formatDocument(document)
        newZip.file(key, documentXML)
      } else if (key === 'word/comments.xml') {
        // skip
        // var commentsXML = this.formatComments(doc.comments)
        // newZip.file(key, commentsXML)
      } else if (key === 'word/_rels/document.xml.rels') {
        // skip
      } else {
        newZip.file(key, zip.file(key).async('blob'))
      }
    }
    if (doc.comments) {
      var commentsXML = this.formatComments(doc.comments)
      newZip.file('word/comments.xml', commentsXML)
    }
    if (doc.relationships2) {
      // var xml = this.getXMLFromJson(doc.relationships2, {
      //   namespace: ''
      // })
      var xml = this.formatRelations()
      newZip.file('word/_rels/document.xml.rels', xml)
    }
    var zipBlob = await newZip.generateAsync({ type: 'blob' })
    var file = new File([zipBlob], 'default.docx', {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    })
    window.open(URL.createObjectURL(file), 'target=_blank')
  }

  getXMLFromJson(json, opt) {
    var $ = cheerio.load('', {
      xml: {
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    this.$ = $
    this.fillXML($.root(), json, opt)
    return $.xml()
  }

  fillXML(node, obj, opt) {
    opt = opt || {
      namespace: 'w:'
    }
    if (Array.isArray(obj)) {
      obj.forEach(child => {
        var childNode = this.$(`<${opt.namespace}${child.tagName}>`)
        this.fillXML(childNode, child, opt)
        node.append(childNode)
      })
      return
    }
    for (var key in obj) {
      var val = obj[key]
      if (key === 'tagName') {
        continue
      }
      if (key === 'content') {
        this.fillXML(node, val, opt)
      } else if ((val && val.tagName) || Array.isArray(val)) {
        var childNode = this.$(`<${opt.namespace}${key}>`)
        this.fillXML(childNode, val, opt)
        node.append(childNode)
      } else if (key === 'text') {
        node.text(val)
      } else {
        if (key.indexOf(':') === -1) {
          key = opt.namespace + key
        }
        node.attr(key, val)
      }
    }
  }

  formatDocument(document) {
    var $ = cheerio.load('<w:document></w:document>', {
      xml: {
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    this.$ = $
    var documentNode = $('w\\:document')
    this.fillXML(documentNode, document)
    // this.debug$($)
    return $.xml()
  }

  formatRelations() {
    // 临时方案
    return `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
    <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId8" Type="http://schemas.microsoft.com/office/2011/relationships/people" Target="people.xml"/><Relationship Id="rId7" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/fontTable" Target="fontTable.xml"/><Relationship Id="rId6" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/customXml" Target="../customXml/item1.xml"/><Relationship Id="rId5" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/theme" Target="theme/theme1.xml"/><Relationship Id="rId4" Type="http://schemas.microsoft.com/office/2011/relationships/commentsExtended" Target="commentsExtended.xml"/><Relationship Id="rId3" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/comments" Target="comments.xml"/><Relationship Id="rId2" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/settings" Target="settings.xml"/><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles" Target="styles.xml"/></Relationships>`
  }

  formatComments(comments) {
    // TODO 此处应该优化
    var $ = cheerio.load('<w:comments xmlns:wpc="http://schemas.microsoft.com/office/word/2010/wordprocessingCanvas" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:wp14="http://schemas.microsoft.com/office/word/2010/wordprocessingDrawing" xmlns:wp="http://schemas.openxmlformats.org/drawingml/2006/wordprocessingDrawing" xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main" xmlns:w14="http://schemas.microsoft.com/office/word/2010/wordml" xmlns:w15="http://schemas.microsoft.com/office/word/2012/wordml" xmlns:w10="urn:schemas-microsoft-com:office:word" xmlns:wpg="http://schemas.microsoft.com/office/word/2010/wordprocessingGroup" xmlns:wpi="http://schemas.microsoft.com/office/word/2010/wordprocessingInk" xmlns:wne="http://schemas.microsoft.com/office/word/2006/wordml" xmlns:wps="http://schemas.microsoft.com/office/word/2010/wordprocessingShape" xmlns:wpsCustomData="http://www.wps.cn/officeDocument/2013/wpsCustomData" mc:Ignorable="w14 w15 wp14"></w:comments>', {
      xml: {
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    var node = $('w\\:comments')
    this.$ = $
    this.fillXML(node, comments)
    return $.xml()
  }

  debug$($) {
    var file = new File([$.xml()], 'document.xml', {
      type: 'text/xml' // 默认使用 text/xml, 例如 .rels
    })
    var url = URL.createObjectURL(file)
    window.open(url, '_blank')
    console.log($.xml())
  }
}

module.exports = Serializer
