<template>
  <div class="word-page" :style="style">
    <slot></slot>
    <word-element :element="element"></word-element>
    <div
      class="word-page-sep"
      v-for="(item, i) in pageCount"
      :style="{ top: (item * page.contentHeight + page.top) + 'pt' }"
      :key="i">
      <span>页码{{item}}</span>
    </div>
  </div>
</template>
<script>
import WordMixin from '@/word/word-mixin'
import docStore from '@/components/docstore'

export default {
  mixins: [WordMixin],
  props: {
    element: Object // 此 element 是 body
  },
  computed: {
    finalDocHeight() {
      var sectPr = this.element.sectPr
      var singleContentHeight = (sectPr.pgSz.h - sectPr.pgMar.top - sectPr.pgMar.bottom) / 20
      return singleContentHeight * this.pageCount + (Number(sectPr.pgMar.top) + Number(sectPr.pgMar.bottom)) / 20
    },
    pageCount() {
      // 单位都是 pt
      var docHeight = docStore.state.docHeight / 4 * 3
      var sectPr = this.element.sectPr
      var allContentHeight = docHeight - sectPr.pgMar.top / 20 - sectPr.pgMar.bottom / 20
      var singleContentHeight = (sectPr.pgSz.h - sectPr.pgMar.top - sectPr.pgMar.bottom) / 20
      var pageCount = allContentHeight / singleContentHeight
      pageCount = Math.ceil(pageCount)
      return Math.max(1, pageCount)
    },
    page() {
      var sectPr = this.element.sectPr
      return {
        width: sectPr.pgSz.w / 20,
        height: sectPr.pgSz.h / 20,
        contentHeight: (sectPr.pgSz.h - sectPr.pgMar.top - sectPr.pgMar.bottom) / 20,
        left: sectPr.pgMar.left / 20,
        top: sectPr.pgMar.top / 20,
        right: sectPr.pgMar.right / 20,
        bottom: sectPr.pgMar.bottom / 20
      }
    },
    style() {
      var style = {}
      var sectPr = this.element.sectPr
      style.width = this.dxa2css(sectPr.pgSz.w)
      style['min-height'] = sectPr.pgSz.h / 20 + 'pt'
      'top left right bottom'.split(' ').forEach(key => {
        style['padding-' + key] = this.dxa2css(sectPr.pgMar[key])
      })
      // style[minheight = this.finalDocHeight + 'pt'
      return style
    }
  }
}
</script>
