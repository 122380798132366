<template>
  <span
    class="word-comment-range-start"
    :data-path="getPath().join('-')"
    :data-comment-id="element.id"></span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import rangeStore from '@/components/rangestore'

export default {
  mixins: [WordMixin],
  created() {
    var point = {
      path: this.getPath(),
      offset: 0
    }
    rangeStore.addStart(`comment-${this.element.id}`, point)
  }
}
</script>
