// parser, serialize 库需要同时支持browser 和 nodejs
const JSZIP = require('jszip')
const _ = require('lodash')
const OoxmlParser = require('./ooxmlparser')

class Parser {
  async parseDocx(docxContent) {
    // https://stuk.github.io/jszip/documentation/api_jszip/file_name.html
    const zip = await JSZIP.loadAsync(docxContent)
    this.zip = zip
    console.log('zip files', zip.files)
    this.relationships = await this.parseRelationships()
    this.relationships2 = await this.parseRelationships2()
    this.numbering = await this.parseNumbering()
    this.document = await this.parseDocument()
    var ret = {
      document: this.document,
      numbering: this.numbering,
      comments: await this.parseComments(),
      relationships: this.relationships,
      relationships2: this.relationships2,
      zip,
      styles: await this.parseStyles()
    }
    return ret
  }

  async getJsonFromXML(filePath) {
    var file = this.zip.file(filePath)
    if (!file) {
      return null
    }
    var xmlString = await file.async('string')
    var ooxmlparser = new OoxmlParser()
    var json = ooxmlparser.parse(xmlString)
    return json
  }

  async parseNumbering() {
    var json = await this.getJsonFromXML('word/numbering.xml')
    var abstractMap = {}
    var numMap = {}

    _.each(json?.numbering, item => {
      if (item.tagName === 'abstractNum') {
        abstractMap[item.abstractNumId] = item
      } else if (item.tagName === 'num') {
        numMap[item.numId] = {
          numId: item.numId,
          ...abstractMap[item.abstractNumId.val]
        }
        _.each(numMap[item.numId].content, level => {
          numMap[item.numId][level.ilvl] = level
        })
        delete numMap[item.numId].content
      }
    })

    return numMap
  }

  async parseRelationships2() {
    return await this.getJsonFromXML('word/_rels/document.xml.rels')
  }

  async parseRelationships() {
    var ret = {}
    var json = await this.getJsonFromXML('word/_rels/document.xml.rels')
    console.log('relation ship', json)
    for (const item of json.Relationships) {
      var target = item.Target
      var file = this.zip.file('word/' + target)
      var type = 'blob'
      if (_.endsWith(target, '.xml')) {
        type = 'string'
      }
      if (file) {
        var content = await file.async(type)
        ret[item.Id] = {
          target,
          content
        }
      }
    }
    return ret
  }

  async parseDocument() {
    var json = await this.getJsonFromXML('word/document.xml')
    return json.document
  }

  async parseComments() {
    var json = await this.getJsonFromXML('word/comments.xml')
    if (json) {
      return json.comments
    }
  }

  async parseStyles() {
    var json = await this.getJsonFromXML('word/styles.xml')
    if (json) {
      return json
    }
  }
}

module.exports = Parser
