<template>
  <div class="alternate-content">
    <word-element v-if="finalElement" :element="finalElement"></word-element>
  </div>
</template>

<script>
export default {
  props: {
    element: Object
  },
  computed: {
    finalElement() {
      var ret = this.element.Choice?.content[0]
      return ret
    }
  }
}
</script>
