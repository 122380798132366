
<template>
  <img
    :src="imageUrl"
    :width="emu2css(element.inline.extent.cx)"
    :height="emu2css(element.inline.extent.cy)">
</template>
<script>
import docStore from '@/components/docstore'
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    imageUrl() {
      var embed = this.element.inline?.graphic?.graphicData?.pic?.blipFill?.blip?.embed
      var file = docStore.state.relationships[embed]
      return URL.createObjectURL(file.content)
    }
  }
}
</script>
