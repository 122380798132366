<template>
  <component
    :is="tagName"
    :data-tag="element.tagName"
    :element="this.isTagSupported() ? element : null">
    <template v-if="element.content && element.content.length">
      <word-element
        v-for="(child, i) in showList || []"
        :key="child.paraId ? child.paraId : i"
        :element="child"></word-element>
    </template>
    <!-- 确保段落有高度 -->
    <span v-else>&ZeroWidthSpace;</span>
  </component>
</template>

<script>
const BLOCK_TAGS = ['p', 'tbl']
const INLINE_TAGS = ['r', 'del', 'ins', 'commentRangeStart', 'commentRangeEnd', 'commentReference', 'bookmarkStart', 'bookmarkEnd', 'drawing', 'hyperlink', 'AlternateContent']
const TEXT_TAGS = ['t', 'delText', 'tab', 'br', 'object']
const SUPPORTED_TAGS = [].concat(BLOCK_TAGS, INLINE_TAGS, TEXT_TAGS)

export default {
  props: {
    element: Object
  },
  data() {
    return {
      renderCount: 30 // 预渲染30行
    }
  },
  computed: {
    showList() {
      if (this.element.content && this.element.content.length) {
        if (this.renderCount) {
          return this.element.content.slice(0, this.renderCount)
        }
        return this.element.content
      }
    },
    tagName() {
      if (this.isTagSupported()) {
        return 'word-' + this.element.tagName
      }
      return 'div'
    }
  },
  mounted() {
    this.tryLazyLoad()
  },
  methods: {
    async tryLazyLoad() {
      await this.$nextTick()
      setTimeout(() => {
        var contentSize = this.element.content?.length || 0
        if (this.renderCount >= contentSize) {
          docStore.updateDocHeight()
          return
        }
        this.renderCount += 30
        this.tryLazyLoad()
      }, 0)
    },
    isTagSupported() {
      return SUPPORTED_TAGS.indexOf(this.element.tagName) !== -1
    }
  }
}
</script>
