import docStore from '@/components/docstore'

const highlightPool = [
  {
    name: 'yellow',
    text: '黄色',
    value: '#FFFF00'
  },
  {
    name: 'green',
    text: '鲜绿',
    value: '#00ff00'
  },
  {
    name: 'cyan',
    text: '青绿',
    value: '#00FFFF'
  },
  {
    value: '#FF00FF'
  },
  {
    value: '#0000FF'
  },
  {
    value: '#FF0000'
  },
  {
    value: '#00008B'
  }
]

export function getText(element) {
  if (!element) {
    return ''
  }
  if (Array.isArray(element.content)) {
    return element.content.map(function(item) {
      return getText(item)
    }).join('')
  } else if (element.text) {
    return element.text
  }
}

export function props2style(props) {
  // word props 转成 css style
  if (!props) {
    return null
  }
  var style = {}
  for (var key in props) {
    var val = props[key]
    if (key === 'jc') {
      style['text-align'] = val.val === 'both' ? 'justify' : val.val
    } else if (key === 'sz') {
      style['font-size'] = val.val / 2 + 'pt'
    } else if (key === 'b') {
      style['font-weight'] = val.val == 0 ? 'normal' : 'bold'
    } else if (key === 'i') {
      style['font-style'] = val.val == 0 ? 'normal' : 'italic'
    } else if (key === 'rFonts') {
      var fontFamily = normalizeFontFamily(val['eastAsia']) || normalizeFontFamily(val['ascii'])
      if (fontFamily) {
        style['font-family'] = fontFamily
      }
    } else if (key === 'u') {
      style['text-decoration'] = val.val === 'none' ? 'none' : 'underline'
    } else if (key === 'spacing') {
      // 后处理
    } else if (key === 'ind') {
      var indStyle = getIndentStyle(val)
      Object.assign(style, indStyle)
    } else if (key === 'szCs' || key === 'bCs' || key === 'iSz') {
      // skip
    } else if (_.endsWith(key, 'paraId') || _.endsWith(key, 'textId')) {
      // skip
    } else if (_.includes(key, 'rsidR') || _.includes(key, 'rsidP')) {
      // skip
    } else if (key === 'rPr') {
      // 后面处理
    } else if (key === 'color') {
      style['color'] = val.val === 'auto' ? '#000' : '#' + val.val
    } else if (key === 'highlight') {
      let item = _.find(highlightPool, {
        name: val.val
      })
      if (item) {
        style['background-color'] = item.value
      } else {
        style['background-color'] = val.val
      }
    } else if (key === 'pStyle') {
      // skip
    } else if (key === 'numPr') {
      // 统一在 num 组件中处理
    } else if (key === 'tabs') {
      // 属性作用未知
    } else if (key === 'lang') {
      // 先只处理中文
    } else if (key === 'widowControl') {
      // 属性作用未知
    } else if (key === 'sectPr') {
      // 属性作用未知
    } else if (key === 'name') {
      // skip
    } else {
      // console.log('unknown prop', key, JSON.stringify(val, 0, 4), JSON.stringify(this.content))
      // console.log('unknown prop', key)
    }
  }

  if (props.spacing) {
    var spacingStyle = getSpacingStyle(props.spacing)
    Object.assign(style, spacingStyle)
  }

  return style
}

export function normalizeFontFamily(str) {
  if (!str) return
  str = str.split(';')[0] // 兼容之前的污染文件或者css格式
  str = str.split(/\sSC$/i).join('')
  var map = {
    'songti': '宋体'
  }
  str = map[str.toLowerCase()] || str
  var arr = [str]
  if (str.length <= 2 && isCn(str)) {
    arr.push(str + '-简', '华文' + str)
  }
  if (str === '微软雅黑') {
    // arr.push('冬青黑体简体中文')
    arr.push('PingFang SC')
  } else if (str === '苹方') {
    arr.push('微软雅黑')
  }
  var ret = arr.map(item => `"` + item + `"`).join(',')
  return ret
}

export function isCn(str) {
  return /^[\u4e00-\u9fa5]+$/i.test(str)
}

export function getDefaultStyle(name) {
  name = name || 'Normal'
  var styleList = docStore.state.styles?.styles?.content
  var matched = _.find(styleList, item => item.name?.val === name)
  return matched
}

export function getNumInfo(numPr) {
  var numberObj = docStore.state.numbering[numPr.numId.val]
  if (numberObj) {
    return numberObj[numPr.ilvl?.val || 0]
  }
}

export function normalizeColor(color) {
  if (!color) {
    return
  }
  if (color === 'auto') {
    return
  }
  if (/^[0-9A-F]{6}$/i.test(color)) {
    return '#' + color
  }
  return color
}

export function translateNum(num, translateString) {
  translateString = translateString || '零一二三四五六七八九十百千'
  var arr = String(num).split('')
  return arr.map((item, i) => {
    var unit = ''
    var unitIndex = arr.length - i - 1
    unit = unitIndex ? translateString[10 + unitIndex - 1] : ''
    if (item == 0) {
      return ''
    }
    if (unitIndex == 1 && item == 1) {
      // 一十应该返回十
      return unit
    }
    return translateString[item] + unit
  }).join('')
}

function getIndentStyle(indent) {
  // 缩进属于段落属性
  var style = {}
  if (!indent) {
    return
  }

  // 特殊格式缩进
  if (indent['hanging']) {
    var val = dxa2css(indent['hanging'])
    style['text-indent'] = '-' + val
    style['padding-left'] = val
  } else if (indent['firstLine']) {
    style['text-indent'] = dxa2css(indent['firstLine'])
  }

  // 文本之前
  if (indent['leftChars']) {
    style['padding-left'] = indent['leftChars'] / 100 + 'em'
  } else if (indent['left']) {
    style['padding-left'] = dxa2css(indent['left'])
  }

  // 文本之后
  if (indent['rightChars']) {
    style['padding-right'] = indent['rightChars'] / 100 + 'em'
  } else if (indent['right']) {
    style['padding-right'] = dxa2css(indent['right'])
  }
  return style
}

function getSpacingStyle(spacing) {
  // spacing 属于段落属性
  var ret = {}
  if (spacing['before']) {
    ret['padding-top'] = dxa2css(spacing['before'])
  }
  if (spacing['after']) {
    ret['padding-bottom'] = dxa2css(spacing['after'])
  }
  return ret
}

export function getLineHeight(fontHeight, spacing) {
  // fontHeight = fontSize, 单位是 pt
  // 多倍行距, 受网格对齐(snaptogrid)影响
  if (!fontHeight || !spacing) {
    return
  }
  var ret = {}
  var lineRule = spacing['lineRule']
  if (lineRule === 'exact') {
    // 固定值
    ret['line-height'] = dxa2css(spacing['line'])
    ret['min-height'] = ret['line-height']
  } else if (lineRule === 'atLeast') {
    // 最小值
    ret['line-height'] = (Math.max(spacing['line'] / 20, fontHeight * 1.297)) + 'pt'
    ret['min-height'] = ret['line-height']
  } else if (lineRule === 'auto') {
     // font size 是行内属性
    var minLineHeight = fontHeight * 1.297
    var linePitch = docStore.state.document.body.sectPr?.docGrid?.linePitch
    var gridHeight = linePitch / 20
    var lineHeightWithoutGrid = fontHeight * spacing['line'] / 240 * 1.297

    if (gridHeight && lineHeightWithoutGrid) {
      var gridCount = 1
      while (true) {
        if (gridHeight * gridCount > minLineHeight) {
          break
        }
        gridCount++
      }
      var lineHeightWithGrid = gridHeight * gridCount
      var lineHeight = Math.max(lineHeightWithoutGrid, lineHeightWithGrid) // 用大的那个
      ret['line-height'] = lineHeight + 'pt'
    } else {
      ret['line-height'] = lineHeightWithoutGrid + 'pt'
    }
  }
  return ret
}

export function pt2px(val) {
  return val * 4 / 3 + 'px'
}

export function emu2css(val) {
  return val / 914400 * 72 + 'pt'
}

export function dxa2css(val) {
  return val / 20 + 'pt'
}

export function dxa2px(val) {
  // 有些地方只能 px，比如表格 grid
  return val / 20 * 4 / 3 + 'px'
}

export function str2entity(str) {
  return str.replace(/./gm, function(s) {
    return (s.match(/[a-z0-9\s]+/i)) ? s : '&#' + s.charCodeAt(0) + ';'
  })
}

export const sharedData = {
  numState: {}
}
