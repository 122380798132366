import _ from 'lodash'
import rangeStore from '@/components/rangestore'
import * as utils from '@/word/utils.js'

export default {
  props: {
    element: Object
  },
  methods: {
    pt2px: utils.pt2px,
    emu2css: utils.emu2css,
    dxa2css: utils.dxa2css,
    dxa2px: utils.dxa2px,
    getParentNode(node) {
      return node.$parent.$parent
    },
    getPath() {
      var path = []
      var currentNode = this
      var parentNode = this.getParentNode(currentNode)
      while (parentNode && parentNode.element) {
        var index = parentNode.element.content.indexOf(currentNode.element)
        path.unshift(index)
        currentNode = parentNode
        parentNode = this.getParentNode(parentNode)
      }
      return path
    },
    comparePath(pathA, pathB) {
      var len = Math.max(pathA.length, pathB.length)
      for (var i = 0; i < len; i++) {
        var valA = pathA[i] || 0
        var valB = pathB[i] || 0
        if (valA > valB) {
          return 1
        } else if (valA < valB) {
          return -1
        }
      }
      return 0
    },
    getBelongRanges() {
      var ret = []
      var path = this.getPath()
      for (let range of rangeStore.state.ranges) {
        if (range.start && range.end) {
          if (this.comparePath(path, range.start.path) === 1 && this.comparePath(path, range.end.path) === -1) {
            ret.push(range)
          }
        }
      }
      return ret
    }
  }
}
