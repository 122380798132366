<template>
  <span :style="numStyle">{{ numStr }}<span class="word-suff" :style="suffStyle" v-if="suff === 'space'" v-html="' '"></span><span class="word-suff" :style="suffStyle" v-else-if="suff === 'tab'" v-html="'\t'"></span></span>
</template>
<script>
import * as utils from '@/word/utils'

const numValueMap = {
  // decimal: '123456789',
  japaneseCounting: '零一二三四五六七八九十百千',
  japaneseCountingThousand: '零一二三四五六七八九十百千',
  chineseCounting: '零一二三四五六七八九十百千',
  chineseCountingThousand: '零一二三四五六七八九十百千'
}

export default {
  props: {
    numPr: Object
  },
  computed: {
    numStr() {
      var { numPr, numInfo } = this
      if (!numPr || !numInfo) {
        return ''
      }
      var state = this.increment(numPr.numId.val, numPr.ilvl?.val || 0)
      return numInfo.lvlText.val.replace(/%(\d)/g, function(_, count) {
        var ret = state[count - 1]
        var translateString = numValueMap[numInfo.numFmt.val]
        if (translateString) {
          ret = utils.translateNum(ret, translateString)
        }
        if (ret === undefined) {
          // 目前不知其规则
          console.log('unknown num', utils.sharedData.numState, numPr)
          ret = ''
        }
        return ret
      })
    },
    suff() {
      if (!this.numInfo) {
        return
      }
      var suff = this.numInfo.suff?.val || 'tab'
      return suff
    },
    numInfo() {
      return utils.getNumInfo(this.numPr)
    },
    suffStyle() {
      var hanging = this.numInfo.pPr?.ind?.hanging || 0
      var magicHanging = 0.74 // 序号默认最小值
      var width = hanging / 20 - (magicHanging / 2.54 * 72)
      if (width < 0) {
        width = 0
      }
      return {
        width: width + 'pt'
      }
    },
    numStyle() {
      var paraStyle = utils.props2style(this.$parent.$parent.element?.pPr?.rPr)
      var numStyle = utils.props2style(this.numInfo?.rPr)
      var style = Object.assign({}, numStyle, paraStyle) // para style 覆盖 num style
      var hanging = this.numInfo?.pPr?.ind?.hanging
      if (hanging) {
        style.width = hanging / 20 + 'pt'
      }
      return style
    }
  },
  methods: {
    str2entity: utils.str2entity,
    increment(numId, ilvl) {
      // 序号计数 {numId: [0count, 1count, 2count]}
      // 类似于 css 的 counter 和 reset
      ilvl = parseInt(ilvl)
      var numState = utils.sharedData.numState
      numState[numId] = numState[numId] || []
      numState[numId][ilvl] = numState[numId][ilvl] || (this.numInfo.start.val - 1)
      numState[numId][ilvl]++
      numState[numId][ilvl + 1] = 0 // reset
      return numState[numId]
    }
  }
}
</script>
