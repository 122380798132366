
<template>
  <span class="word-del-text">{{ element.text }}</span>
</template>
<script>
export default {
  props: {
    element: Object
  }
}
</script>
