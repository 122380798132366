import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 未来可能会有多个 cursor
const cursor = {
  namespaced: true,
  state: () => {
    return {
      path: [0],
      offset: 2
    }
  },
  mutations: {
    update(state, payload) {
      Object.assign(state, payload)
    },
    move(state, payload) {
      state.offset += payload
    }
  }
}

const doc = {
  namespaced: true,
  state: () => {
    return {
      tag: 'Document',
      props: {},
      children: [{
        tag: 'Paragraph',
        props: {},
        content: '我()是一句话'
      }, {
        tag: 'Paragraph',
        props: {},
        content: '第二句话'
      }]
    }
  },
  mutations: {
    update(state, payload) {
      const { op, value, range } = payload
      const { path, offset } = range[1]
      const element = getElementByPath(state, path)
      if (op === 'addText') {
        element.content = element.content.slice(0, offset) + value + element.content.slice(offset)
      } else if (op === 'deleteText') {
        element.content = element.content.slice(0, offset - 1) + element.content.slice(offset)
      }
    }
  }
}

function getElementByPath(doc, path) {
  var ret = doc
  path.forEach(item => {
    ret = ret.children[item]
  })
  return ret
}

export default new Vuex.Store({
  modules: {
    cursor,
    doc
  }
})
