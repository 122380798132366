<template>
  <table class="word-table" border="1" cellspacing="0" cellpadding="0">
    <colgroup>
      <col
        v-for="(item, i) in element.tblGrid"
        :key="i"
        :width="dxa2px(item.w)">
    </colgroup>
    <tr
      v-for="(row, i) in rows"
      :key="i"
      :style="row.style">
      <td
        v-for="(cell, i) in row.content"
        :key="i"
        :colspan="cell.colspan"
        :rowspan="cell.rowspan"
        :style="cell.style">
        <word-element :element="cell"></word-element>
      </td>
    </tr>
  </table>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import docStore from '@/components/docstore'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    rows() {
      var rows = []
      _.each(this.element.content, (row, rowIndex) => {
        var cells = []
        var trHeight = row.trPr?.trHeight?.val || 0
        var rowStyle = {
          'height': this.dxa2px(trHeight)
        }
        _.each(row.content, (cell, cellIndex) => {
          var colspan = cell.tcPr?.gridSpan?.val
          var rowspan = null
          var vMergeObject = cell.tcPr?.vMerge
          if (vMergeObject && vMergeObject.val === 'restart') {
            rowspan = 1
            for (let i = 1; ; i++) {
              var nextRow = this.element.content[rowIndex + i]
              if (!nextRow) {
                break
              }
              var cellBelow = nextRow.content[cellIndex]
              var cellBelowVMergeObject = cellBelow?.tcPr?.vMerge
              if (cellBelowVMergeObject && cellBelowVMergeObject.val !== 'restart') {
                rowspan++
              } else {
                break
              }
            }
          } else if (vMergeObject) {
            // 有 vMerge 的默认值就是 continue，不一定有
            return
          }
          var vAlign = cell.tcPr?.vAlign?.val
          var tblPr = Object.assign({}, this.getDefaultTableStyle()?.tblPr, this.element.tblPr)

          var cellStyle = {
            'padding-left': this.dxa2px(tblPr?.tblCellMar?.left?.w),
            'padding-right': this.dxa2px(tblPr?.tblCellMar?.right?.w),
            'vertical-align': vAlign === 'center' ? 'middle' : vAlign,
            'background-color': utils.normalizeColor(cell.tcPr?.shd?.fill),
            'color': utils.normalizeColor(cell.tcPr?.shd?.color)
          }
          cells.push({
            content: cell.content,
            colspan,
            rowspan,
            style: cellStyle
          })
        })
        rows.push({
          style: rowStyle,
          content: cells
        })
      })
      return rows
    }
  },
  methods: {
    getDefaultTableStyle() {
      var styleList = docStore.state.styles?.styles?.content
      var matched = _.find(styleList, style => {
        return style.name.val === 'Normal Table'
      })
      return matched
    }
  }
}
</script>
