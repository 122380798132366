<template>
  <a :href="href" class="word-hyperlink" :target="element.anchor ? null : '_blank'" :style="style">
    <slot></slot>
  </a>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import * as utils from '@/word/utils.js'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    style() {
      return {}
    },
    href() {
      if (this.element.anchor) {
        return `#` + this.element.anchor
      }
      var text = utils.getText(this.element)
      return text
    }
  },
  methods: {
  }
}
</script>
