<template>
  <span class="word-ins" @click="handleClick">
    <word-num v-if="element.rPr && element.rPr.numPr" :numPr="element.rPr.numPr"></word-num>
    <slot></slot>
  </span>
</template>

<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  methods: {
    handleClick() {
      console.log('修订新增', {
        author: this.element.author,
        date: this.element.date
      })
    }
  }
}
</script>
