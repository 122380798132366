
<template>
  <span class="word-br" v-html="'\n'"></span>
</template>
<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin]
}
</script>
