<template>
  <span
    class="word-bookmark-range-end"
    :data-path="getPath().join('-')"></span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import rangeStore from '@/components/rangestore'

export default {
  mixins: [WordMixin],
  created() {
    var point = {
      path: this.getPath(),
      offset: 0
    }
    rangeStore.addEnd(`bookmark-${this.element.id}`, point)
  }
}
</script>
