<template>
  <div class="review-results" v-if="results">
    <div
      v-for="(item, i) in results"
      :key="i"
      class="review-item"
      @click="selectComment(item.commentId)"
      :class="{'review-item-selected': item.commentId === selectedCommentId}">
      <div v-html="item.suggestion"></div>
    </div>
  </div>
</template>

<script>
import docStore from '@/components/docstore'

export default {
  props: {
    results: Array
  },
  computed: {
    selectedCommentId() {
      return docStore.state.selectedCommentId
    }
  },
  methods: {
    selectComment(commentId) {
      docStore.state.selectedCommentId = commentId
      var element = document.querySelector(`[data-comment-id="${commentId}"]`)
      if (element) {
        element.scrollIntoViewIfNeeded()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.review-item {
  background: #fff;
  font-size: 14px;
  padding: 15px;
  cursor: pointer;
  color: #333;
  margin-bottom: 20px;
}
.review-item-selected {
  box-shadow: rgb(0 0 0 / 16%) 0 2px 10px 0;
}
</style>
