
<template>
  <span v-if="element.text" class="word-t" v-html="text" :data-path="this.getPath().join('-')"></span>
</template>
<script>
import WordMixin from '@/word/word-mixin'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  computed: {
    text() {
      // http://www.javashuo.com/article/p-crkwqnss-mm.html
      // &nbsp; 不换行空格
      // &ensp; 半角空格一个英文/数字宽度, em宽度的一半
      // &emsp; 全角空格, 一个中文字符宽度 = 1em
      // &thinsp; 窄空格，1/6em, 效果最贴近 word
      return this.element.text.replace(/ /g, '&ensp;') // 经过测试, 空格确实占半角
    }
  }
}
</script>
